import { useReducer } from 'react';

export default numSlides => {
  const [activeSlide, dispatch] = useReducer((currentSlide, action) => {
    switch (action.type) {
      case 'NEXT_SLIDE':
        return currentSlide < numSlides - 1 ? currentSlide + 1 : 0;
      case 'PREVIOUS_SLIDE':
        return currentSlide > 0 ? currentSlide - 1 : numSlides - 1;
      case 'SET_SLIDE':
        return action.value;
      default:
        return currentSlide;
    }
  }, 0);

  return [
    activeSlide,
    () => dispatch({ type: 'PREVIOUS_SLIDE' }),
    () => dispatch({ type: 'NEXT_SLIDE' }),
    num => dispatch({ type: 'SET_SLIDE', value: num }),
  ];
};
